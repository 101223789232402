import Olmoforms from "@olmokit/core/olmoforms/feedback";
import FormsInputMaterial from "@olmokit/core/forms/input/material";
import FormsSelectMaterial from "@olmokit/core/forms/select/material";
import Dependency from "@olmokit/core/olmoforms/dependency";
import "@olmokit/core/forms/checkbox";
import "@olmokit/core/forms/textarea/filled";
import "./index.scss";

/**
 * Component: FormNewsletter
*
*/
export function FormNewsletter() {
  
  const olmoform = Olmoforms(".FormNewsletter:", {
    sent: (formData) => {
      dataLayer.push({
        ...formData,
        event: "form sent",
        form: "Newsletter Form",
      });
    },
  });
  
  FormsInputMaterial();
  FormsSelectMaterial();
  Dependency(".FormNewsletter:");
  
  return {
    destroy() {
      olmoform.destroy();
    },
  };

}
