import { listen } from "@olmokit/dom";
import { addFormsRedirect } from "@olmokit/core/auth/redirect";
import { globalData } from "@olmokit/core/data";
import { getAuthDialog } from "./index";
import "./index.scss";

export default function promptAuthDialog() {
  const { isGuest } = globalData?.authDialog || {};
  let authDialog;

  if (isGuest) {
    authDialog = getAuthDialog();
    listen("click", `[data-auth-prompt]`, handleClick);
  }

  /**
   * Handle click, hijack auth links to auth dialog
   *
   * @param {MouseEvent} event
   */
  function handleClick(event) {
    event.preventDefault();
    authDialog.show("login");
    addFormsRedirect(event.target.href);
  }
}
