/**
 * @file Route: home
 *
 * This file is compiled as a standalone entrypoint and it is included by
 * default in its blade template.
 * This is a "standard" route, it does not have a structure to follow, you are
 * sure the DOM is always ready and the JS will only be executed in this route.
 */
import "layouts/main";
import { $ } from "@olmokit/dom";
import { Header } from "components/Header/Info";
import "@olmokit/core/responsive/only/md";
import { Storefinder } from "components/Storefinder";
import { SliderHome } from "components/Slider/Home";
import { replaceFragment } from "@olmokit/core/fragments";
import { FormNewsletter } from "components/Form/Newsletter";
import "./index.scss";

console.log("Route home/index.js mounted.");

Header();
SliderHome(".Slider:");
const $root = $('.Storefinder:img');
Storefinder($root).init();
replaceFragment("newsletterForm", "components.FormNewsletter").then(() => FormNewsletter());