import { $ } from "@olmokit/dom";
import { Searchaddress } from "components/Searchaddress";
import animatedGlobe from "utils/animatedGlobe";
import "./index.scss";

/**
 * Component: Storefinder
 */
export function Storefinder($root) {
  console.log("Storefinder mounted.");

  function init() {
    const $wrap = $(".Storefinder:", $root);
    Searchaddress($wrap).init();
    // if (__DEV__) console.log("storefinder->init with $root", $root);

    animatedGlobe($root);
  }

  return {
    init,
  };  
}