import { $, $all, on, forEach, addClass } from "@olmokit/dom";
import { breakpoints, gridGutter } from "@olmokit/core/scss";
import {
  glide,
  Anchors,
  Autoplay,
  Breakpoints,
  Controls,
  // LazyLoad,
  CrossFade,
  // AutoHeight,
  Swipe,
} from "@olmokit/core/glide";
import "@olmokit/core/glide/bullets";
import "@olmokit/core/glide/arrows";
import Bullspin from "utils/bullspin";
import "./index.scss";

/**
 * Component: SliderHome
 */
export function SliderHome(rootSelector) {
  console.log("SliderHome mounted.");

  let slidelength = $(rootSelector).getAttribute('data-slide');
  if (parseInt(slidelength) >= 1 ) {
    startSlider(rootSelector);
  }

}

function startSlider(element) {
  const $container = `${element ? element + " " : ""}`;
  const slider = glide(
    $container,
    {
      loop: true,
      autoplay: 3500,
      hoverpause: true,
      animationDuration: 1000,
      killWhen: { above: 99999 },
      bound: true,
      perView: 1,
      gap: 0,
      peek: 0,
    },
    { Anchors, 
      Autoplay, 
      Breakpoints, 
      Controls,
      // LazyLoad, 
      CrossFade,
      // AutoHeight,
      Swipe,
      Bullspin,
     }
      );
      
      return slider;
}
