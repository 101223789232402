import { $, $all } from "@olmokit/dom";
import { gsap } from "gsap";
import DrawSVGPlugin from "utils/DrawSVGPlugin";
gsap.registerPlugin(DrawSVGPlugin);
import "./index.scss";

/**
 * Spinning bullets component for glide slider
 * 
 * It requires the following template:
 * ```
{% for slide in slider %}
  <button class="glide__bullet" data-glide-dir="={{ loop.index - 1 }}">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8 8" class="bullspin">
      <circle cx="4" cy="4" r="4" class="bullspinBg"/>
      <circle cx="4" cy="4" r="4" class="bullspinFg "/>
    </svg>
  </button>
{% endfor %}
 *```
 */
 export default function Bullspin(Glide, Components, Events) {
  const fgByIdx = {};
  const bgByIdx = {};

  /**
   * Map bullets by idx
   */
  function mapBulletsBydx() {
    const $bullets = $all(".glide__bullet", Components.Html.root);

    for (let i = 0; i < $bullets.length; i++) {
      bgByIdx[i] = $(".bullspinBg", $bullets[i]);
      fgByIdx[i] = $(".bullspinFg", $bullets[i]);
      // gsap.set(fgByIdx[i], { drawSVG: "0%" });
    }
  }

  function animate() {
    const $bg = bgByIdx[Glide.index];
    const $fg = fgByIdx[Glide.index];
    gsap.fromTo(
      $bg,
      { scale: 0, opacity: 0 },
      { scale: 1, opacity: 1, duration: 0.3 }
    );
    gsap.fromTo(
      $fg,
      { drawSVG: "100%" },
      {
        drawSVG: "0%",
        duration: Glide.settings.autoplay / 1000,
        onComplete: () => {
          gsap.to($bg, { duration: 0.3, scale: 0, opacity: 0 });
        },
      }
    );
  }

  Events.on("mount.after", animate);
  Events.on("run", animate);

  return {
    mount() {
      mapBulletsBydx();
    },
  };
}
