export const GMAP_ASYNC_BUNDLE_KEY = "gmaps";
export const PLACE_ASYNC_BUNDLE_KEY = "places";

export const GMAP_API_KEY = "AIzaSyDRSSlxqRXArawbMzXgjuq34JYMPCm6dP0"; 
// acanto: AIzaSyBgSDa1bRZeo_nw2NCJloiP6TV8XudUD_o
// acusa: AIzaSyDRSSlxqRXArawbMzXgjuq34JYMPCm6dP0

export const GMAP_SCRIPT_URL = `https://maps.googleapis.com/maps/api/js?key=${GMAP_API_KEY}&libraries=places,geometry`;

export const GMAP_USA_CENTER = {
  lat: 40.979898,
  lng: -94.967069,
};

export const GMAP_USA_BOUNDS = {
  north: 49.86, // 49.38,
  south: 23.62, // 25.82,
  east: -69.09, // -66.94,
  west: -126.22, // -124.39,
};
