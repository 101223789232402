import BaseHeader from "@olmokit/components/Header";
import { $, $all, forEach, on } from "@olmokit/dom";
import "@olmokit/core/responsive/only/lg";
import { Searcher } from "components/Searcher";
// import { clickMenu } from "components/Dl";
import "utils/mmenulight/mmenu-light";
import "utils/mmenulight/mmenu-light.polyfills";
import "utils/mmenulight/mmenu-light.scss";
// import "utils/logo.scss";
import "./index.scss";

/**
 * Component: Header
 *
 */
export function Header() {

  // Searcher();

  class Header extends BaseHeader {
    created() {
      super.created();
    }
  }
  new Header();
  
  const openers = $all(".Header:nav__opener");
  const flyouts = $all(".Header:nav__submenu");
  if (openers) {
    forEach(openers, o => {
      on(o, "click", () => {
        if (o.classList.contains("is-open")) {
          forEach(openers, op => {
            op.classList.remove("is-open");
          });
          forEach(flyouts, f => {
            f.classList.remove("is-open");
          });
        } else {
          forEach(openers, op => {
            op.classList.remove("is-open");
          });
          forEach(flyouts, f => {
            f.classList.remove("is-open");
          });
          o.classList.toggle("is-open");
          $("#" + o.getAttribute("data-href")).classList.toggle("is-open");
        }
      });
    });
  }
  
  const ctas = $all(".dlClickMenu");
  if (ctas) {
    forEach(ctas, c => {
      on(c, "click", () => {
        // clickMenu(c.getAttribute("data-folder"), c.getAttribute("data-item"));
      });
    });
  }
  
  // MMENU.JS
  var mmenu = new MmenuLight(
    document.querySelector("#mmenu"),
    "(max-width: 1199px)"
  );
  var navigator = mmenu.navigation({
    selectedClass: "Selected",
    slidingSubmenus: true,
    theme: "light",
    title: "MENU"
  });
  var drawer = mmenu.offcanvas({
    position: "right"
  });
  // Open the mmenu.
  document.querySelector('a[href="#mmenu"]').addEventListener("click", evnt => {
    evnt.preventDefault();
    drawer.open();
  });  
  
}
