import { $, listen } from "@olmokit/dom";
import { getUrlQueryParams } from "@olmokit/utils/getUrlQueryParams";
import { mergeUrlQueryParams } from "@olmokit/utils/mergeUrlQueryParams";
import { removeUrlQueryParams } from "@olmokit/utils/removeUrlQueryParams";
import BaseDialog from "@olmokit/core/dialog";
import "./index.scss";

/**
 * Update url parameter for deeplinking
 *
 * @param {string} queryParam
 * @param {boolean} [opened]
 */
export function udpdateDeeplink(queryParam, opened) {
  if (opened) {
    mergeUrlQueryParams({ [queryParam]: true }, true);
  } else {
    removeUrlQueryParams(queryParam, true);
  }
}

/**
 * Basic auto intialisation of dialogs, it cactch links with the right data
 * attribute and prompt the dialog, same on load based on current query params
 *
 * @param {dialogInstance} instance
 * @param {string} [method="open"] The instance method name to use to open the the dialog, this allows implementers to easily override the open behaviour
 */
export function dialogInit(instance, method = "open") {
  const queryParams = getUrlQueryParams();

  listen("click", `[data-${instance.id}]`, handleClick);

  if (queryParams[instance.id]) {
    instance[method]();
  }

  /**
   * Handle click, hijack auth links to auth dialog
   *
   * @param {MouseEvent} event
   */
  function handleClick(event) {
    event.preventDefault();
    instance[method](event.target);
  }
}

/**
 * Get basic deeplinkable dialog options
 *
 * @param {string} queryParam
 */
export function getBasicHooks() {
  return {
    opening: (instance) => udpdateDeeplink(instance.id, true),
    closing: (instance) => udpdateDeeplink(instance.id, false),
  };
}

/**
 *
 * @type {Dialog.initialiser}
 */
export default function Dialog(rooter, options = {}, hooks = {}) {
  options.gaps = [$(".Header:")];

  const instance = BaseDialog(rooter, options, hooks);

  if (options.id) {
    instance.id = options.id;
  }
  return instance;
}
